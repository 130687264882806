// eslint-disable-next-line import/no-self-import
import Axios from 'axios';

import { BASE_URL } from '../config';

// eslint-disable-next-line import/prefer-default-export
export const axios = Axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axios.interceptors.response.use(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (response) => response.data,
    (error) => Promise.reject(error),
);
